<template>
  <div class="square-coloured rounded-sm" :style="style"></div>
</template>

<script>

export default {
  props: {
    colour: {
      type: String,
      default: null,
    },
  },
  computed: {
    style () {
      if (!this.colour) {
        return {};
      }

      return {
        'background-color': this.colour,
      };
    },
  },
};

</script>

<style lang="scss">

.square-coloured {
  width: 20px;
  height: 20px;
  // border: 1px solid black;
  background-color: white;
  display: inline-block;
}

</style>
